<script>
import { getUser, delUser } from '@/api/admin/user'
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-vue-grids'

import Dropdown from '@/components/ui/Dropdown.vue'
import AddBtn from '@/components/ui/AddBtn.vue' // 등록

export default {
  name: 'UserList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    Dropdown
  },

  data() {
    return {
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbarOptions: ['Search'],
      format: { type: 'dateTime', format: 'y/M/d' },
      data: [],
      commands: [{ buttonOption: { content: '삭제', cssClass: 'e-danger del' } }],
      persistedData: [],
      searchConditions: [
        { value: 'groupName', text: '그룹' },
        { value: 'userId', text: '아이디' },
        { value: 'labName', text: '연구실' },
        { value: 'name', text: '이름' },
        { value: 'email', text: '이메일' }
      ],
      searchBy: ''
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, CommandColumn]
  },
  async mounted() {
    this.setSearchOptions()
    this.data = (await getUser()).items.filter((item) => item.groupName !== 'bot')

    this.persistedData = this.data
  },
  methods: {
    adduser() {
      this.$router.push({ path: '/admin/users/usercreate' })
    },
    rowSelected(e) {
      const target = e.target
      if (target.matches('button')) return

      this.$router.push({ path: `/admin/users/usercreate/${e.data.userId}` })
    },
    commandClick(e) {
      const target = e.target
      const userId = e.rowData.userId

      if (target.matches('.mod')) return this.modUser(userId)
      if (target.matches('.del')) return this.delUser(userId)
    },

    modUser(userId) {
      this.$router.push({ path: `/admin/users/usercreate/${userId}` })
    },

    async delUser(userId) {
      if (!confirm('정말로 삭제하시겠습니까?')) return

      const result = await delUser(userId)
      if (result?.code) alert(result.message)

      this.data = this.data.filter((item) => item.userId !== userId)
    },
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      this.data = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    }
  }
}
</script>

<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h4>회원관리</h4>
  </nav>
  <div id="content-wrapper" class="d-flex flex-column">
    <AddBtn @click="adduser()"></AddBtn>
    <Dropdown
      class="select-wrapper"
      :options="searchConditions"
      :disabled="false"
      name="zipcode"
      ref="selectOption"
      @selected="selectDropdownHandler"
    ></Dropdown>
    <div id="content">
      <div class="">
        <ejs-grid
          :dataSource="data"
          :allowPaging="true"
          :pageSettings="pageSettings"
          :allowSorting="true"
          :toolbar="toolbarOptions"
          :rowSelected="rowSelected"
          :commandClick="commandClick"
          :actionComplete="actionComplete"
          ref="grid"
        >
          <e-columns>
            <e-column field="groupName" headerText="그룹" width="150" textAlign="center" />
            <e-column field="userId" headerText="아이디" width="120" textAlign="center" />
            <e-column field="labName" headerText="연구실" width="150" textAlign="center" />
            <e-column field="name" headerText="이름" width="150" textAlign="center" />
            <e-column field="email" headerText="이메일" width="150" textAlign="center" />
            <e-column field="lastSignInDate" headerText="접속일" width="130" :format="format" type="date" textAlign="center" />
            <e-column field="createdAt" headerText="생성일" width="130" :format="format" type="date" textAlign="center" />
            <e-column field="updatedAt" headerText="수정일" width="130" :format="format" type="date" textAlign="center" />
            <e-column field="manage" headerText="관리" width="130" textAlign="center" :commands="commands" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
